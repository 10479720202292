import { Link } from "react-router-dom";
import {  useAppSelector } from "../../store/hooks";
import { TopNewsType } from "../../models";
import parse from "html-react-parser";
import React from "react";


const NewsList =React.forwardRef<HTMLDivElement>((props, ref)=> {
  const { newsList } = useAppSelector((state) => state.common);
  
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-5 mt-10 text-justify">
      {newsList?.results?.map((i: TopNewsType) => (
        <div
          key={i?.id}
          className="group bg-white rounded-lg shadow-outline hover:shadow-hover hover:outline hover:outline-2 hover:outline-green-500"
        >
          <Link to={`/news/details/${i?.id}`}>
            <div className="relative flex items-end justify-center">
              <div className="item_thumb w-full aspect-h-2 aspect-w-3 relative">
                <img
                  className="absolute inset-0 rounded-t-lg"
                  src={i?.image}
                  alt=""
                />
              </div>
              <div className="bg-gradient-to-l from-[#ffffff94] to-[#ffffff85] py-1 px-[6px] backdrop-filter rounded w-auto h-14 absolute -bottom-7 right-6 border-b border-green-500">
                <div className="text-gray-900 text-center font-semibold">
                  {i?.view}
                </div>
                <small className="text-gray-900 text-center font-normal">
                  {i?.published_date}
                </small>
              </div>
            </div>
          </Link>
          <div className="p-4">
            <span
              style={{ padding: "4px 12px", borderRadius: "3px" }}
              className="bg-green-200 text-sm font-normal text-success-500"
            >
              {i?.uploader_name?.first_name} {i?.uploader_name?.last_name}
            </span>
            <Link to={`/news/details/${i?.id}`}>
              <h6 className="lg:text-lg text-base font-medium text-gray-900 group-hover:text-green-500 py-2 max-h-20 h-20 overflow-clip">
                {i?.title}
              </h6>
            </Link>
            <div className="relative flex items-center justify-center">
              <div className="text-gray-700 py-2 max-h-28 text-clip overflow-hidden">
                {/* {i?.content} */}
                {i?.content && parse(i?.content)}
              </div>
              <Link
                to={`/news/details/${i?.id}`}
                className="bg-gradient-to-l from-[#ffffff94] to-[#ffffff85] py-1 px-[6px] backdrop-filter rounded w-full absolute -bottom-1  text-center hover:scale-110"
              >
                <div className="text-gray-900 text-center font-normal hover:text-green-500">
                  View More
                </div>
              </Link>
            </div>
            {/* <div className="flex items-center">
              <img
                alt=""
                src={ambImg}
                className="w-8 h-8 mr-4 rounded-full outline outline-1 outline-white"
              />
              <p className="text-gray-900 py-2">Robiul Alom</p>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
});

export default NewsList;
