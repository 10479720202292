import parse from "html-react-parser";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";

const PetitionDetailsContentSummery = () => {
  const { petitionDetails } = useAppSelector((state) => state.petition);
  return (
    <div className="lg:col-span-8 col-span-12">
      <div className="bg-white p-6 rounded-md shadow-theme_shadow">
        <div className="flex flex-wrap items-center gap-2 mb-5">
          <p className="text-green-500 text-base font-normal">
            Petition Created By:
            <span className="font-medium">{petitionDetails?.created_by}</span>,
          </p>
          <span className="text-[#4a4a4a] text-opacity-70">
            {petitionDetails?.published_datetime
              ? moment(petitionDetails.published_datetime).format(
                  "MMM D, YYYY h:mm A"
                )
              : "N/A"}
          </span>
        </div>
        {petitionDetails?.description && parse(petitionDetails?.description)}
        {/* <div className="text-black text-opacity-70 text-base font-normal">
          <p className="pb-4">মাননীয় দশম বাংলাদেশ সংসদ,</p>
          <p>
            আমরা সম্প্রতি গণমাধ্যমে ২০১৭ সালের মাধ্যমিক পরীক্ষার প্রশ্নপত্র
            ফাঁসের ঘটনাগুলো পড়ে অত্যন্ত মর্মাহত ও বিষন্ন। এ ব্যাপারটি যদি বন্ধ
            করা না যায় তাহলে জাতি হিসেবে আমাদের ভবিষ্যৎ অন্ধকার। সংসদে পিটিশনের
            সুযোগটি ব্যবহার করে এ ভয়ংকর সংস্কৃৃতি বন্ধ করার ব্যাপারে আমরা কিছু
            একটা করতে চাই। দশম সংসদ যদি ব্যাপারটি নিয়ে কোন একটি অধিবেশনে
            বিস্তারিত আলাপ করে আর একটি সংসদীয় তদন্ত কমিটি গঠন করে পুরো ঘটনাটি
            অনুসন্ধান করে তাহলে আমরা চিরকৃতজ্ঞ থাকব।
          </p>
          <p className="py-4">
            তদন্ত কমিটি অনুসন্ধানের জন্য আমরা নিচের প্রশ্নগুলো আবেদন করলাম।
          </p>
          <ul className="list-disc pl-4">
            <li>
              <p>
                ২০১৭ সালের মাধ্যমিক পরীক্ষার প্রশ্নপত্র ফাঁস বন্ধের জন্য শিক্ষা
                মন্ত্রণালয়ের কি কি দাপ্তরিক উদ্যোগ ছিল?
              </p>
            </li>
            <li>
              <p>
                ২০১৭ সালের মাধ্যমিক পরীক্ষার প্রশ্নপত্র ফাঁস বন্ধের জন্য শিক্ষা
                মন্ত্রণালয়ের কতটুকু তহবিল বরাদ্দ ছিল?
              </p>
            </li>
            <li>
              <p>
                ২০১৭ সালের মাধ্যমিক পরীক্ষার প্রশ্নপত্র ফাঁস প্রথম যখন শুরু হয়
                অর্থাৎ ফেব্রুয়ারী ৭, ২০১৭ থেকে এখন পর্যন্ত কি কি পদক্ষেপ নেয়া
                হয়েছে?
              </p>
            </li>
            <li>
              <p>
                যদি প্রস্তুতি থেকে থাকে তাহলে তারপরও কেন ২০১৭ সালের মাধ্যমিক
                পরীক্ষার প্রশ্নপত্র ফাঁস হল? আমাদের আবেদনগুলি বিবেচনা করে যদি
                সংসদে পাঠ করা হয় আমরা ভীষণ কৃতজ্ঞ থাকব।
              </p>
            </li>
          </ul>
          <div className="pt-6">
            <p>বিনীত,</p>
            <p>ওমর শেহাব</p>
            <p>ঢাকা-১৭ নির্বাচনী এলাকার একজন ভোটার।</p>
            <p>Email:shehab1@umbc.edu</p>
          </div>
        </div> */}
        <div className="pt-8">
          <h4 className="text-black text-opacity-60 xl:text-3xl md:text-2xl text-xl font-semibold">
            Petition Statistics
          </h4>
          <p className="text-black text-opacity-70 text-base font-normal py-2">
            Target: {petitionDetails?.target}
          </p>
          <p className="text-black text-opacity-70 text-base font-normal">
            Total: {petitionDetails?.signatures_count ? petitionDetails?.signatures_count : 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PetitionDetailsContentSummery;
