import { Link } from "react-router-dom";
import { QuestionType } from "../../../models";
import { useAppSelector } from "../../../store/hooks";


const TopQuestion = () => {
    const {topQuestion} = useAppSelector(state => state.quesAns)
  return (
    <div className="top_qa_update">
      <div className="bg-white p-5 text-center shadow-theme_shadow">
        <h3 className="text-green-500 xl:text-2xl md:text-xl text-lg font-medium pb-8">
          Top
          <span className="text-red-500 inline-block relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
            Question
          </span>
          Update
        </h3>
        {topQuestion?.length > 0 ? (
          <ul className="flex flex-col gap-5 divide-y max-h-[600px] overflow-y-auto">
            {topQuestion?.map((item: QuestionType) => (
              <li className="flex flex-col gap-3 pt-5" key={item?.id}>
                <Link
                  to={`/neta/details/${item?.member}?q=${item?.id}`}
                  state={{ paramsData: 3, paramsType: "Question" }}
                >
                  <div className="ad_content flex items-center gap-2">
                    <img
                      className="w-[50px] h-[50px] rounded-full border-2 border-green-500"
                      src={item?.sender?.pro_pic}
                      alt=""
                    />

                    <div className="text-left">
                      <p className="inline-block text-green-500 md:text-base text-sm font-normal">
                        {item?.sender?.first_name} {item?.sender?.last_name}
                      </p>
                      <p className="text-left text-[#484848] md:text-base text-sm font-normal">
                        {item?.published_date}
                      </p>
                    </div>
                  </div>
                  <div className="adm_title flex items-start gap-2">
                    <i></i>
                    <h4 className="text-left text-[#484848] md:text-base text-sm font-normal">
                      {item?.subject}
                    </h4>
                  </div>
                </Link>
              </li>
            ))}

            {/* <li className="flex flex-col gap-3 pt-5">
            <div className="ad_content flex items-center gap-2">
              <img
                className="w-[50px] h-[50px] rounded-full border-2 border-green-500"
                src=""
                alt=""
              />
              <div className="text-left">
                <p className="inline-block text-green-500 md:text-base text-sm font-normal">
                  AmarNeta Admin
                </p>
                <p className="text-left text-[#484848] md:text-base text-sm font-normal">
                  May 31, 2023, 12:22am
                </p>
              </div>
            </div>
            <div className="adm_title flex items-start gap-2">
              <i></i>
              <h4 className="text-left text-[#484848] md:text-base text-sm font-normal">
                মোটরসাইকেলের নাম্বারপ্লেইট সাংবাদিক সাইনবোর্ড-কতোটুকু আইনসম্মত?
              </h4>
            </div>
          </li>
          <li className="flex flex-col gap-3 pt-5">
            <div className="ad_content flex items-center gap-2">
              <img
                className="w-[50px] h-[50px] rounded-full border-2 border-green-500"
                src=""
                alt=""
              />
              <div className="text-left">
                <p className="inline-block text-green-500 md:text-base text-sm font-normal">
                  AmarNeta Admin
                </p>
                <p className="text-left text-[#484848] md:text-base text-sm font-normal">
                  May 31, 2023, 12:22am
                </p>
              </div>
            </div>
            <div className="adm_title flex items-start gap-2">
              <i></i>
              <h4 className="text-left text-[#484848] md:text-base text-sm font-normal">
                মোটরসাইকেলের নাম্বারপ্লেইট সাংবাদিক সাইনবোর্ড-কতোটুকু আইনসম্মত?
              </h4>
            </div>
          </li>
          <li className="flex flex-col gap-3 pt-5">
            <div className="ad_content flex items-center gap-2">
              <img
                className="w-[50px] h-[50px] rounded-full border-2 border-green-500"
                src=""
                alt=""
              />
              <div className="text-left">
                <p className="inline-block text-green-500 md:text-base text-sm font-normal">
                  AmarNeta Admin
                </p>
                <p className="text-left text-[#484848] md:text-base text-sm font-normal">
                  May 31, 2023, 12:22am
                </p>
              </div>
            </div>
            <div className="adm_title flex items-start gap-2">
              <i></i>
              <h4 className="text-left text-[#484848] md:text-base text-sm font-normal">
                মোটরসাইকেলের নাম্বারপ্লেইট সাংবাদিক সাইনবোর্ড-কতোটুকু আইনসম্মত?
              </h4>
            </div>
          </li>
          <li className="flex flex-col gap-3 pt-5">
            <div className="ad_content flex items-center gap-2">
              <img
                className="w-[50px] h-[50px] rounded-full border-2 border-green-500"
                src=""
                alt=""
              />
              <div className="text-left">
                <p className="inline-block text-green-500 md:text-base text-sm font-normal">
                  AmarNeta Admin
                </p>
                <p className="text-left text-[#484848] md:text-base text-sm font-normal">
                  May 31, 2023, 12:22am
                </p>
              </div>
            </div>
            <div className="adm_title flex items-start gap-2">
              <i></i>
              <h4 className="text-left text-[#484848] md:text-base text-sm font-normal">
                মোটরসাইকেলের নাম্বারপ্লেইট সাংবাদিক সাইনবোর্ড-কতোটুকু আইনসম্মত?
              </h4>
            </div>
          </li> */}
          </ul>
        ) : (
          <p className="inline-block text-green-500 md:text-base text-sm font-normal">
            Question Not Found
          </p>
        )}
      </div>
    </div>
  );
};

export default TopQuestion;
