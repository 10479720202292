
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useEffect, useState } from "react";
import {
  getDistrictList,
  getDivisionList,
  getSeatList,
  getUpazilaList,
} from "../../../store/apis";
import SelectComponent from "../../CommonComponent/SelectComponent";
import { DistrictType, SeatType, UpazilaType } from "../../../models";

type PropsType = {
  handleSearchSubmit: (data: any) => void
  title?: string
}

const SearchNeta = (props: PropsType) => {
  const { division, district, upazila, seat } = useAppSelector(
    (state) => state.common
  );
  
  const [divisionData, setDivisionData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [districtData, setDistrictData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [upazilaData, setUpazilaData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [seatData, setSeatData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [divisionOption, setDivisionOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [districtOption, setDistrictOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [upazilaOption, setUpazilaOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [seatOption, setSeatOption] = useState<
    { value: number; label: string }[]
  >([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDivisionList());
    dispatch(getDistrictList());
    dispatch(getUpazilaList());
    dispatch(getSeatList());
  }, [dispatch]);
  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (division?.length > 0) {
      division.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setDivisionOption(option);
  }, [division]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (district?.length > 0) {
      district?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setDistrictOption(option);
  }, [district]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (upazila?.length > 0) {
      upazila?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setUpazilaOption(option);
  }, [upazila]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (seat?.length > 0) {
      seat?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setSeatOption(option);
  }, [seat]);

  const handleDivisionSelectChange = (selectedOption: any) => {
    setDivisionData(selectedOption);
    setDistrictData(null);
    setUpazilaData(null);
    // Filter districts based on the selected division
    if (selectedOption) {
      const filteredDistricts = district?.filter(
        (item: DistrictType) => item.division.id === selectedOption.value
      );
      let option: { value: number; label: string }[] = [];
      if (filteredDistricts) {
        filteredDistricts.map((item: { id: number; name: string }) =>
          option.push({ value: item.id, label: item.name })
        );
      }
      setDistrictOption(option);
    } else {
      // If no division is selected, show all districts
      let option: { value: number; label: string }[] = [];
      district?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
      setDistrictOption(option);
    }
  };

  const handleDistrictSelectChange = (selectedOption: any) => {
    setDistrictData(selectedOption);
    setUpazilaData(null);
    if (selectedOption) {
      const filteredUpazila = upazila?.filter(
        (item: UpazilaType) => item.district.id === selectedOption.value
      );
      let option: { value: number; label: string }[] = [];
      if (filteredUpazila) {
        filteredUpazila.map((item: { id: number; name: string }) =>
          option.push({ value: item.id, label: item.name })
        );
      }
      setUpazilaOption(option);
    } else {
      // If no division is selected, show all districts
      let option: { value: number; label: string }[] = [];
      district?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
      setDistrictOption(option);
    }
  };
  const handleUpazilaSelectChange = (selectedOption: any) => {
    setUpazilaData(selectedOption)
    
    if (selectedOption) {
      const filteredSeat = seat?.filter(
        (item: SeatType) => item.upazila.includes(selectedOption.value)
        // (item: SeatType) => item.upazila === selectedOption.value
      );
      let option: { value: number; label: string }[] = [];
      if (filteredSeat) {
        filteredSeat.map((item: { id: number; name: string }) =>
          option.push({ value: item.id, label: item.name })
        );
      }
      setSeatOption(option);
      // setUpazilaData({
      //   id: selectedOption.value,
      //   name: selectedOption.label,
      // });
    } else {
      let option: { value: number; label: string }[] = [];
      upazila?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
      setUpazilaOption(option);
      // setUpazilaData(null);
    }
  };

  const handleSeatSelectChange = (selectedOption: any) => {
    setSeatData(selectedOption)
    // if (selectedOption) {
    //   setSeatData({
    //     value: selectedOption.value,
    //     label: selectedOption.label,
    //   });
    // } else {
    //   setUpazilaData(null);
    // }
  };

  const handleClick = () => {
    if (seatData) {
      props.handleSearchSubmit({ seat: seatData });
    } else if (upazilaData) {
      props.handleSearchSubmit({ upazila: upazilaData });
    } else if (districtData) {
      props.handleSearchSubmit({ district: districtData });
    } else if (divisionData) {
      props.handleSearchSubmit({ division: divisionData });
    } else {
      props.handleSearchSubmit(null);
    }
  }

  return (
    <section className="neta_search lg:pt-10 pt-8 lg:sticky lg:top-[24px] z-[111]">
      <div className="bg-white py-6 rounded-md shadow-theme_shadow">
        <div className="container-fluid mx-auto">
          <div className="search_list grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
            <div className="search_item">
              <div className="search_item bg-white rounded-md shadow-theme_shadow relative">
                <SelectComponent
                  selectOptions={divisionOption}
                  selectData={divisionData ? divisionData : undefined}
                  setSingleSelectData={setDivisionData}
                  handleSelectChange={handleDivisionSelectChange}
                  placeholder="Choose Your Division"
                  clearable={false}
                  searchable={true}
                />
              </div>
            </div>
            <div className="search_item">
              <div className="search_item bg-white rounded-md shadow-theme_shadow relative">
                <SelectComponent
                  selectOptions={districtOption}
                  selectData={districtData ? districtData : undefined}
                  setSingleSelectData={setDistrictData}
                  handleSelectChange={handleDistrictSelectChange}
                  placeholder="Choose Your District"
                  clearable={false}
                  searchable={true}
                />
              </div>
            </div>
            <div className="search_item">
              <div className="search_item bg-white rounded-md shadow-theme_shadow relative">
                <SelectComponent
                  selectOptions={upazilaOption}
                  selectData={upazilaData}
                  setSingleSelectData={setUpazilaData}
                  handleSelectChange={handleUpazilaSelectChange}
                  placeholder="Choose Your Upazila"
                  clearable={false}
                  searchable={true}
                />
              </div>
            </div>

            <div className="search_item">
              <div className="search_item bg-white rounded-md shadow-theme_shadow relative">
                <SelectComponent
                  selectOptions={seatOption}
                  selectData={seatData}
                  setSingleSelectData={setSeatData}
                  handleSelectChange={handleSeatSelectChange}
                  placeholder="Choose Your Seat"
                  clearable={false}
                  searchable={true}
                />
              </div>
            </div>
            {/* <div className="search_item">
              <div className="search_item bg-white rounded-md shadow-theme_shadow relative">
                <select
                  name=""
                  id="division"
                  className="bg-transparent h-10 p-2 w-full text-black text-sm appearance-none focus:outline-none"
                >
                  <option value="0" selected>
                    Choose Your Seat
                  </option>
                  <option value="1">Noakhali-1</option>
                  <option value="2">Noakhali-2</option>
                </select>
                <i className="absolute top-1/2 right-2 -translate-y-1/2 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </i>
              </div>
            </div> */}
            <div className="search_item">
              <button
                onClick={handleClick}
                // to={"/neta/list"}
                className="search_item flex items-center justify-center bg-green-500 h-10 p-2 w-full text-white text-sm font-normal rounded-md shadow-theme_shadow"
              >
                Find {props?.title}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchNeta;
