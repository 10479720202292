import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import React from "react";

type PropsType = {
  setOpen: (a: boolean) => void;
};

const NetaProfile: React.FC<PropsType> = ({ setOpen }) => {
  const { memberDetails } = useAppSelector((state) => state.member);
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <section className="profile_area lg:py-10 py-8">
      <div className="md:container container-fluid mx-auto">
        <div className="profile_top bg-white shadow-theme_shadow rounded-md p-4 flex md:flex-row flex-col items-start gap-3">
          <div className="profile_thumb w-[150px] h-[150px] rounded-md">
            {memberDetails?.profile_pic && (
              <img
                className="w-full rounded-md"
                src={memberDetails?.profile_pic}
                alt=""
              />
            )}
          </div>
          <div className="profile_content md:w-[calc(100%_-_112px)] flex lg:flex-row flex-col gap-3">
            <div className="profile_text text-start lg:w-[calc(100%_-_212px)]">
              <h3 className="text-black md:text-3xl sm:text-2xl text-xl font-semibold">
                {memberDetails?.name}
              </h3>
              <p className="text-[#4a4a4a] text-sm font-normal pb-2">
                {memberDetails?.designation}
              </p>
              <div className="mp_location flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <i>
                    <img
                      src="https://img.icons8.com/ios/16/4a4a4a/marker--v1.png"
                      alt="marker--v1"
                    />
                  </i>
                  <span className="inline-block text-[#4a4a4a] text-sm font-normal">
                    {memberDetails?.district?.name} ,{" "}
                    {memberDetails?.division?.name}
                  </span>
                </div>
                <Link to="/" className="flex items-center gap-2">
                  <i>
                    <img
                      src="https://img.icons8.com/material-outlined/16/22c55e/organization.png"
                      alt="organization"
                    />
                  </i>
                  <span className="inline-block text-green-500 text-sm font-normal">
                    {memberDetails?.political_party?.name}
                  </span>
                </Link>
                <ul className="flex items-center gap-2">
                  {memberDetails?.facebook_link && (
                    <li>
                      <Link
                        to={memberDetails?.facebook_link}
                        className="inline-block h-6 w-6 leading-6 rounded-full bg-green-500 text-center"
                        target="_blank"
                      >
                        <img
                          className="mx-auto my-1"
                          src="https://img.icons8.com/ios-glyphs/16/ffffff/facebook-f.png"
                          alt="facebook-f"
                        />
                      </Link>
                    </li>
                  )}

                  {memberDetails?.linkedIn_link && (
                    <li>
                      <Link
                        to={memberDetails?.linkedIn_link}
                        className="inline-block h-6 w-6 leading-6 rounded-full bg-green-500 text-center"
                        target="_blank"
                      >
                        <img
                          className="mx-auto my-1"
                          src="https://img.icons8.com/ios-glyphs/16/ffffff/linkedin.png"
                          alt="linkedin"
                        />
                      </Link>
                    </li>
                  )}

                  {memberDetails?.twitter_link && (
                    <li>
                      <Link
                        to={memberDetails?.twitter_link}
                        className="inline-block h-6 w-6 leading-6 rounded-full bg-green-500 text-center"
                        target="_blank"
                      >
                        <img
                          className="mx-auto my-1"
                          src="https://img.icons8.com/ios-glyphs/16/ffffff/twitter--v1.png"
                          alt="twitter--v1"
                        />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="public_social">
              <ul className="flex lg:flex-col flex-row flex-wrap gap-2">
                <li>
                  <button
                    onClick={() => {
                      user?.id
                        ? setOpen(true)
                        : navigate(
                            `/login?next=/neta/details/${memberDetails?.id}`
                          );
                    }}
                    className="bg-red-500 p-2 sm:w-[200px] xs:w-[160px] text-center rounded-md flex items-center gap-2"
                  >
                    <i>
                      <img
                        src="https://img.icons8.com/windows/20/ffffff/chat-message.png"
                        alt="chat-message"
                      />
                    </i>
                    <span className="text-white text-base font-medium">
                      Ask Question
                    </span>
                  </button>
                </li>
                {memberDetails?.email && (
                  <li>
                    <Link
                      to={`mailto:${memberDetails?.email}`}
                      className="bg-red-500 p-2 sm:w-[200px] xs:w-[160px] text-center rounded-md flex items-center gap-2"
                    >
                      <i>
                        <img
                          src="https://img.icons8.com/material-outlined/20/ffffff/new-post.png"
                          alt="new-post"
                        />
                      </i>
                      <span className="text-white text-base font-medium">
                        Email to MP
                      </span>
                    </Link>
                  </li>
                )}
                {memberDetails?.phone && (
                  <li>
                    <Link
                      to={`tel:${memberDetails?.phone}`}
                      className="bg-red-500 p-2 sm:w-[200px] xs:w-[160px] text-center rounded-md flex items-center gap-2"
                    >
                      <i>
                        <img
                          src="https://img.icons8.com/metro/20/ffffff/phone.png"
                          alt="phone"
                        />
                      </i>
                      <span className="text-white text-base font-medium">
                        {memberDetails?.phone}
                      </span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NetaProfile;
