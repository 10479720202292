import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { formats, modules } from "../Neta/Details/AskQuestionModel";

export interface PetitionFormData {
  title: string;
  target: string;
  description: string;
  expire_datetime: Date | null;
  isPublished?: boolean;
  status?: string;
}

interface FormErrors {
  title?: string;
  target?: string;
  description?: string;
  expire_datetime?: string;
}

interface PetitionCreateUpdateFormProps {
  initialData?: PetitionFormData; // Optional prop for editing
  onSubmit: (data: PetitionFormData) => void; // Submit handler
}

const PetitionCreateUpdateForm: React.FC<PetitionCreateUpdateFormProps> = ({
  initialData,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<PetitionFormData>({
    title: "",
    target: "",
    description: "",
    expire_datetime: null,
    isPublished: false,
  });

    const [errors, setErrors] = useState<FormErrors>({});
    
    useEffect(() => {
      if (initialData) {
        setFormData(initialData); // Set form data if editing
      }
    }, [initialData]);

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleExpireDatetimeChange = (date: Date | null) => {
    const utcDate = date ? new Date(date.toISOString()) : null;
    setFormData({ ...formData, expire_datetime: utcDate });
    setErrors({ ...errors, expire_datetime: "" });
  };


  // Handle Quill editor changes
  const handleDescriptionChange = (value: string) => {
    setFormData({ ...formData, description: value });
    setErrors({ ...errors, description: "" });
  };

  // Form validation
const validateForm = (): boolean => {
  const newErrors: FormErrors = {};
  if (!formData.title) newErrors.title = "Title is required.";
  if (!formData.target) newErrors.target = "Target is required.";
  if (!formData.description || formData.description === "<p><br></p>")
    newErrors.description = "Description is required.";
  if (!formData.expire_datetime)
    newErrors.expire_datetime = "Expiration datetime is required.";
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

  // Form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
        onSubmit({
          ...formData,
          status: formData?.isPublished ? "published" : "draft",
        });
      // Add submit logic
    }
  };

  const handlePublishToggle = () => {
    setFormData((prevData) => ({
      ...prevData, // Keep all previous properties
      isPublished: !prevData.isPublished, // Toggle isPublished
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4 relative w-full bg-white group rounded-md">
        <input
          id="title"
          type="text"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
          placeholder=""
        />
        <label
          htmlFor="title"
          className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
        >
          Title <span className="text-red-500">&nbsp; *</span>
        </label>
        {errors.title && (
          <p className="text-red-500 text-xs mt-1">{errors.title}</p>
        )}
      </div>

      <div className="mb-4 relative w-full bg-white group rounded-md">
        <input
          id="target"
          type="text"
          name="target"
          value={formData.target}
          onChange={handleInputChange}
          className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
          placeholder=" "
        />
        <label
          htmlFor="target"
          className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
        >
          Target <span className="text-red-500">&nbsp; *</span>
        </label>
        {errors.target && (
          <p className="text-red-500 text-xs mt-1">{errors.target}</p>
        )}
      </div>

      <div className="mb-4 relative w-full bg-white group rounded-md">
        <label
          htmlFor="content"
          className="ml-[14px] flex items-center px-1 rounded-[3px] text-xs font-normal text-gray-700"
        >
          Description <span className="text-red-500">&nbsp; *</span>
        </label>
        <ReactQuill
          className="mt-2"
          value={formData.description}
          onChange={handleDescriptionChange}
          modules={modules}
          formats={formats}
          placeholder="Write the description of the petition"
        />
        {errors.description && (
          <p className="text-red-500 text-xs mt-1">{errors.description}</p>
        )}
      </div>

      <div className="mb-4 relative w-full bg-white group rounded-md">
        <input
          type="datetime-local"
          id="expire_datetime"
          name="expire_datetime"
          value={
            formData.expire_datetime
              ? new Date(formData.expire_datetime).toISOString().slice(0, 16)
              : ""
          }
          onChange={(e) =>
            handleExpireDatetimeChange(
              e.target.value ? new Date(e.target.value) : null
            )
          }
          className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
          placeholder=" "
        />
        <label
          htmlFor="expire_datetime"
          className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
        >
          Expire Datetime <span className="text-red-500">&nbsp; *</span>
        </label>
        {errors.target && (
          <p className="text-red-500 text-xs mt-1">{errors.target}</p>
        )}
      </div>
      <div className="mb-4 flex items-center gap-2">
        <label
          htmlFor="publish-toggle"
          className="text-sm font-medium text-gray-700"
        >
          Publish
        </label>
        <input
          id="publish-toggle"
          type="checkbox"
          checked={formData.isPublished}
          onChange={handlePublishToggle}
          className="toggle-checkbox h-5 w-10 bg-gray-200 rounded-full"
        />
        <span className="text-sm text-gray-600">
          {formData.isPublished ? "Published" : "Draft"}
        </span>
      </div>

      <div className="input_btn text-center sm:w-[112px] mx-auto mt-4 w-full">
        <button
          type="submit"
          className="bg-green-500 text-black 2xl:text-lg text-base 2xl:h-[45px] h-10 px-2 w-full rounded-md hover:bg-theme_color transition-all"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default PetitionCreateUpdateForm;
