import { useAppSelector } from '../../store/hooks';
import { petitionType } from '../../models';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AiOutlineEdit } from 'react-icons/ai';

const MyPetitionList = () => {
    const { ownerPetitionList } = useAppSelector(state => state.petition)
    
  return (
    <div className="relative overflow-x-auto pt-10 text-start">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Target
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Approved
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Published Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Expire Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {ownerPetitionList?.results?.map((item: petitionType) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <Link
                  to="/neta/details/5"
                  className="text-green-500 hover:underline"
                  state={{ paramsData: 3, paramsType: "Answer" }}
                >
                  {item?.title}
                  {/* {item?.content && (
                    <div className="max-w-[200px] overflow-hidden overflow-ellipsis">
                      {parse(item.content)}
                    </div>
                  )} */}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                <a href="answer-view.html">{item?.target}</a>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.approved ? "Approved" : "Pending"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.published_datetime
                  ? moment(item.published_datetime).format("MMM D, YYYY h:mm A")
                  : "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                {item?.expire_datetime
                  ? moment(item.expire_datetime).format("MMM D, YYYY h:mm A")
                  : "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-[200px] overflow-hidden overflow-ellipsis">
                <Link to={`/petition/edit/${item?.id}`}>
                  <AiOutlineEdit className="mr-2 text-yellow-500" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MyPetitionList