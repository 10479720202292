
import { Link } from "react-router-dom";
import { MemberType } from "../../../models";
import { useAppSelector } from "../../../store/hooks";

interface PageProps {
  limit: number;
  total?: number;
  selectedPage?: number;
}

const ListContent: React.FC<PageProps> = ({limit, total, selectedPage=0}) => {
  const { memberList } = useAppSelector((state) => state.member);
  return (
    <div className="md:w-[calc(100%_-_220px)] w-full">
      <div x-show="openTab === 1">
        <div className="bg-white py-6 px-3 rounded-md shadow-theme_shadow mb-5">
          <p className="text-[#4a4a4a] text-base font-normal">
            Showing MP {(selectedPage - 1) * limit + 1} to{" "}
            {total ? Math.min((selectedPage) * limit, total) : 0} -
            Total: {total}
          </p>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-md shadow-theme_shadow">
          {memberList?.results?.members?.map((item: MemberType) => (
            <div
              key={item?.id}
              className="flex sm:flex-row flex-col sm:items-center gap-5 p-4 border-b border-dashed"
            >
              <div className="mp_thumb h-[80px] w-[80px]">
                <img
                  className="w-full h-full rounded-full border-2 border-green-500"
                  src={item?.profile_pic}
                  alt={item?.name}
                />
              </div>
              <div className="mp_content md:w-[calc(100%_-_100px)] w-full">
                <div className="flex xl:flex-row flex-col items-start justify-between sm:gap-3 gap-1">
                  <div className="mp_titile max-w-[300px]">
                    <Link to={`/neta/details/${item?.id}`}>
                      <h4 className="text-black md:text-xl text-lg font-medium hover:text-red-500">
                        {item?.name}
                      </h4>
                      <span className="text-green-500 text-base font-normal">
                        {item?.designation}
                      </span>
                    </Link>
                  </div>
                  <div className="mp_location flex items-center gap-2">
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                    </i>
                    <span className="text-[#4a4a4a] text-base font-normal">
                      {typeof item?.seat === "object" ? item.seat.name : ""}
                    </span>
                  </div>
                  <div className="mp_party">
                    <span className="text-[#4a4a4a] text-base font-normal">
                      {typeof item?.political_party === "object"
                        ? item?.political_party?.name
                        : ""}
                    </span>
                  </div>
                  <div className="mp_wealth">
                    {/* <a href="#0">
                      <span className="text-green-500 text-base font-normal">
                        Wealth
                      </span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListContent;
