
import { useEffect } from 'react';
import PetitionBanner from '../../components/Petition/PetitionBanner'
import PetitionList from '../../components/Petition/PetitionList'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { publicPetitionListAction } from '../../store/API/PetitionApis';
import Pagination from '../../components/common/Pagination';

const Petition = () => {
  const { publicPetitionList } = useAppSelector((state) => state.petition);
  const dispatch = useAppDispatch();
  // const [remountComponent, setRemountComponent] = useState(0);
  // const navigate = useNavigate();
  const limit = 10;
  useEffect(() => {
    dispatch(publicPetitionListAction({ page: 1, page_size: limit }));
  }, [dispatch]);

  const handlePageClick = (data: any) => {
    dispatch(
      publicPetitionListAction({
        page: data.selected + 1,
        page_size: limit,
      })
    );
  };
  return (
    <div className="text-left">
      <PetitionBanner />
      <PetitionList />
      {publicPetitionList?.results &&
        publicPetitionList?.results?.length > 0 && (
          <div className="m-4">
            <Pagination
              pageCount={
                publicPetitionList?.count &&
                Math.ceil(publicPetitionList?.count / limit)
              }
              handlePageClick={handlePageClick}
              pageRange={5}
            />
          </div>
        )}
    </div>
  );
}

export default Petition