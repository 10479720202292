import React from "react";
import { Helmet } from "react-helmet-async";

type PageProps = {
  title?: string;
  description?: string;
  name?: string;
  type?: string;
  image?: string; // Add image prop
  url?: string; // Add image prop
};

export const SEO: React.FC<PageProps> = ({
  title = "News Title", // Default title
  description = "News description", // Default description
  name,
  type = "website", // Default type
  image = "https://amarneta.com/static/media/logoImg.e3293644afe3a6fca299.jpg", // Default image URL
  url = window.location.href, // Default URL
}) => {
  return (
    <Helmet defer={false} prioritizeSeoTags>
      {/* Facebook (Open Graph) tags */}
      <meta property="og:type" content={type} data-rh="true" />
      <meta property="og:title" content={title} data-rh="true" />
      <meta property="og:description" content={description} data-rh="true" />
      <meta property="og:image" content={image} data-rh="true" />
      <meta property="og:image:width" content="1200" data-rh="true" />
      <meta property="og:image:height" content="630" data-rh="true" />
      <meta property="og:url" content={url} data-rh="true" />
      {/* End Facebook tags */}

      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} data-rh="true" />
      {/* End standard metadata tags */}

      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* End Twitter tags */}
    </Helmet>
  );
};
