import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  DistrictType,
  MemberType,
  SeatType,
  UpazilaType,
} from "../../../models";
import SelectComponent from "../../CommonComponent/SelectComponent";
import { Link } from "react-router-dom";

interface FormData {
  name: string;
  designation: string;
  email: string;
  phone: string;
  birth_day: string;
  education: string;
  profession: string;
  present_address: string;
  permanent_address: string;
  twitter_link: string;
  facebook_link: string;
  linkedIn_link: string;
}

const memberTypeOptions = [
  { value: "REGULAR", label: "Regular" },
  { value: "SHADOW", label: "Shadow" },
  { value: "PROSPECTIVE", label: "Prospective" },
];

const NetaCreateForm = ({
  onSubmit,
  details,
}: {
  onSubmit: Function;
  details?: MemberType;
}) => {
  const { division, district, upazila, seat, partyList } = useAppSelector(
    (state) => state.common
  );
  const [formData, setFormData] = useState<FormData>({
    name: "",
    designation: "",
    email: "",
    phone: "",
    birth_day: "",
    education: "",
    profession: "",
    present_address: "",
    permanent_address: "",
    twitter_link: "",
    facebook_link: "",
    linkedIn_link: "",
  });
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [coverPic, setCoverPic] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setProfilePic(file); // Set the profilePic state separately
  };
  const handleCoverFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] || null;
    setCoverPic(file); // Set the profilePic state separately
  };

  const [divisionData, setDivisionData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [districtData, setDistrictData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [upazilaData, setUpazilaData] = useState<
    { value: number; label: string }[]
  >([]);
  const [seatData, setSeatData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [memberType, setMemberType] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [politicalParty, setPoliticalParty] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [divisionOption, setDivisionOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [districtOption, setDistrictOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [upazilaOption, setUpazilaOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [seatOption, setSeatOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [partyOption, setPartyOption] = useState<
    { value: number; label: string }[]
  >([]);

  // Fetch options
  useEffect(() => {
    setDivisionOption(
      division?.map((item: any) => ({ value: item.id, label: item.name })) || []
    );
    if (details?.division) {
      let a = division?.filter((item: any) => item.id === details.division);
      if (a.length > 0) {
        setDivisionData({ label: a[0].name, value: a[0].id });
      }
    }
  }, [division, details]);

  useEffect(() => {
    setDistrictOption(
      district?.map((item: any) => ({ value: item.id, label: item.name })) || []
    );
    if (details?.district) {
      let a = district?.filter((item: any) => item.id === details.district);
      if (a.length > 0) {
        setDistrictData({ label: a[0].name, value: a[0].id });
      }
    }
  }, [district, details]);

  useEffect(() => {
    setUpazilaOption(
      upazila?.map((item: any) => ({ value: item.id, label: item.name })) || []
    );
    if (
      Array.isArray(details?.upazila) &&
      details?.upazila &&
      details?.upazila?.length > 0
    ) {
      const matchedOptions = upazila?.filter((item: any) =>
        details?.upazila?.includes(item.id)
      );
      if (matchedOptions.length > 0) {
        setUpazilaData(
          matchedOptions.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        );
      }
    }
  }, [upazila, details]);

  useEffect(() => {
    setSeatOption(
      seat?.map((item: any) => ({ value: item.id, label: item.name })) || []
    );
    if (details?.seat) {
      let a = seat?.filter((item: any) => item.id === details.seat);
      if (a.length > 0) {
        setSeatData({ label: a[0].name, value: a[0].id });
      }
    }
  }, [seat, details]);

  useEffect(() => {
    setPartyOption(
      partyList?.map((item: any) => ({ value: item.id, label: item.name })) ||
        []
    );
  }, [partyList]);

  useEffect(() => {
    if (details) {
      setFormData({
        ...formData,
        name: details.name,
        phone: details.phone,
        email: details.email,
        birth_day: details.birth_day || "",
        designation: details.designation,
        education: details.education,
        facebook_link: details.facebook_link || "",
        twitter_link: details.twitter_link || "",
        linkedIn_link: details.linkedIn_link || "",
        permanent_address: details.permanent_address || "",
        present_address: details.present_address || "",
        profession: details.profession || "",
      });
      if (details.political_party) {
        let a = partyOption?.filter(
          (item) => item.value === details.political_party
        );
        if (a.length > 0) {
          setPoliticalParty({ label: a[0].label, value: a[0].value });
        }
      }
      if (details.member_type) {
        let a = memberTypeOptions?.filter(
          (item) => item.value === details.member_type
        );
        if (a.length > 0) {
          setMemberType({ label: a[0].label, value: a[0].value });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    details,
    partyOption,
    memberTypeOptions,
  ]);

  const handleDivisionSelectChange = (selectedOption: any) => {
    setDivisionData(selectedOption);
    setDistrictData(null);
    // setUpazilaData([]);
    setSeatData(null);
    const filteredDistricts = selectedOption
      ? district.filter(
          (item: DistrictType) => item.division.id === selectedOption.value
        )
      : district;
    setDistrictOption(
      filteredDistricts.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
    );
  };

  const handleDistrictSelectChange = (selectedOption: any) => {
    setDistrictData(selectedOption);
    // setUpazilaData([]);
    setSeatData(null);
    const filteredUpazila = selectedOption
      ? upazila.filter(
          (item: UpazilaType) => item.district.id === selectedOption.value
        )
      : upazila;
    setUpazilaOption(
      filteredUpazila.map((item: any) => ({ value: item.id, label: item.name }))
    );
  };

  const handleUpazilaSelectChange = (selectedOptions: any) => {
    // setUpazilaData(selectedOptions || []);
    setSeatData(null);
    const filteredSeat = selectedOptions.length
      ? seat.filter((item: SeatType) =>
          selectedOptions.some((upazila: { value: number }) =>
            item.upazila.includes(upazila.value)
          )
        )
      : seat;
    setSeatOption(
      filteredSeat.map((item: any) => ({ value: item.id, label: item.name }))
    );
  };

  const handleSeatSelectChange = (selectedOption: any) => {
    setSeatData(selectedOption);
  };

  const handlePoliticalPartyChange = (selectedOption: any) => {
    setPoliticalParty(selectedOption);
  };

  const handleMemberTypeChange = (selectedOption: any) => {
    setMemberType(selectedOption);
  };

  const handleSubmit = async () => {
    onSubmit(
      formData,
      profilePic,
      coverPic,
      divisionData,
      districtData,
      upazilaData,
      seatData,
      memberType,
      politicalParty
    );
  };

  return (
    <div className="p-6">
      <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
        <div className="w-full xl:w-1/3">
          <label className="mb-2 block text-black dark:text-white">
            Division
          </label>
          <SelectComponent
            selectOptions={divisionOption}
            selectData={divisionData ?? undefined}
            setSingleSelectData={setDivisionData}
            handleSelectChange={handleDivisionSelectChange}
            placeholder="Choose Your Division"
            clearable={false}
            searchable={true}
          />
        </div>
        <div className="w-full xl:w-1/3">
          <label className="mb-2 block text-black dark:text-white">
            District
          </label>
          <SelectComponent
            selectOptions={districtOption}
            selectData={districtData ?? undefined}
            setSingleSelectData={setDistrictData}
            handleSelectChange={handleDistrictSelectChange}
            placeholder="Choose Your District"
            clearable={false}
            searchable={true}
          />
        </div>
        <div className="w-full xl:w-1/3">
          <label className="mb-2 block text-black dark:text-white">
            Upazila
          </label>
          <SelectComponent
            selectOptions={upazilaOption}
            selectData={upazilaData}
            setMultiSelectData={setUpazilaData}
            handleSelectChange={handleUpazilaSelectChange}
            placeholder="Choose Your Upazila"
            clearable={false}
            searchable={true}
            isMultiple={true}
          />
        </div>
      </div>

      <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
        <div className="w-full xl:w-1/2">
          <label className="mb-2 block text-black dark:text-white">Seat</label>
          <SelectComponent
            selectOptions={seatOption}
            selectData={seatData}
            setSingleSelectData={setSeatData}
            handleSelectChange={handleSeatSelectChange}
            placeholder="Choose Your Seat"
            clearable={false}
            searchable={true}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <label className="mb-2 block text-black dark:text-white">
            Member Type
          </label>
          <SelectComponent
            selectOptions={memberTypeOptions as any}
            selectData={memberType as any}
            setSingleSelectData={setMemberType as any}
            handleSelectChange={handleMemberTypeChange}
            placeholder="Choose Your Member Type"
            clearable={false}
            searchable={true}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <label className="mb-2 block text-black dark:text-white">
            Political Party
          </label>
          <SelectComponent
            selectOptions={partyOption}
            selectData={politicalParty}
            setSingleSelectData={setPoliticalParty}
            handleSelectChange={handlePoliticalPartyChange}
            placeholder="Choose Your Political Party"
            clearable={false}
            searchable={true}
          />
        </div>
      </div>

      <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-6">
        {Object.keys(formData).map((field) => (
          <div key={field} className="w-full">
            <label className="mb-2 block text-black dark:text-white">
              {field.split(/(?=[A-Z])/).join(" ")}
            </label>
            <input
              type="text"
              value={formData[field as keyof FormData] || ""}
              onChange={(e) =>
                setFormData({ ...formData, [field]: e.target.value })
              }
              placeholder={`Enter ${field}`}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
        ))}
        <div className="w-full">
          <label className="mb-2 block text-black dark:text-white">
            Profile Picture
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full py-3"
          />
          <strong className="mt-2">
            {details?.profile_pic ? (
              <Link
                to={details?.profile_pic}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Profile Picture
              </Link>
            ) : (
              "N/A"
            )}
          </strong>
        </div>
        <div className="w-full">
          <label className="mb-2 block text-black dark:text-white">
            Cover Picture
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleCoverFileChange}
            className="w-full py-3"
          />
          <strong className="mt-2">
            {details?.cover_pic ? (
              <Link
                to={details?.cover_pic}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Cover Picture
              </Link>
            ) : (
              "N/A"
            )}
          </strong>
        </div>
      </div>

      <div className="mb-4">
        <button
          onClick={handleSubmit}
          className="w-full rounded bg-blue-600 py-3 text-white hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default NetaCreateForm;
