import React, { useEffect, useState } from "react";
import ModalContainer from "../common/ModalContainer";
import { useForm, Controller } from "react-hook-form";
import { PartyType, UserDetailsType } from "../../models";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateUserProfile } from "../../store/API/UserApis";
import Select from "react-select";
import { customStyles } from "../Admin/District/DistrictCreate";
import { getPartyList } from "../../store/API/CommonApis";
import { getDistrictList, getDivisionList, getSeatList, getUpazilaList } from "../../store/apis";

type PropsTypes = {
  open: boolean;
  closeModal: () => void;
  userDetails: UserDetailsType | null;
};
const ProfileEditForm: React.FC<PropsTypes> = ({
  open,
  closeModal,
  userDetails,
}) => {
  const { partyList, division, district, upazila, seat } = useAppSelector(
    (state) => state.common
  );
  const [partyOption, setPartyOption] = useState<
    { value: number; label: string }[]
    >([]);
  const [divisionOption, setDivisionOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [districtOption, setDistrictOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [upazilaOption, setUpazilaOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [seatOption, setSeatOption] = useState<
    { value: number; label: string }[]
  >([]);

  const dispatch = useAppDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (userDetails?.political_party && partyOption.length > 0) {
      let a: any = partyOption.find(
        (item) => item.label === userDetails?.political_party
      );
      setValue("political_party", { value: a?.value, label: a?.label });
    }
    if (userDetails?.division && divisionOption.length > 0) {
      let a: any = divisionOption.find(
        (item) => item.value === userDetails?.division?.id
      );
      setValue("division", { value: a?.value, label: a?.label });
    }
    if (userDetails?.district && districtOption.length > 0) {
      let a: any = districtOption.find(
        (item) => item?.value === userDetails?.district?.id
      );
      setValue("district", { value: a.value, label: a.label });
    }
    if (
      userDetails?.upazila &&
      userDetails?.upazila?.length > 0 &&
      upazilaOption.length > 0
    ) {
      let a: any = upazilaOption.find(
        (item) => item.value === userDetails?.upazila[0]?.id
      );
      setValue("upazila", { value: a?.value, label: a?.label });
    }
    if (userDetails?.seat && seatOption.length > 0) {
      let a: any = seatOption.find(
        (item) => item?.value === userDetails?.seat?.id
      );
      setValue("seat", { value: a?.value, label: a?.label });
    }
    setValue("first_name", userDetails?.user?.first_name);
    setValue("last_name", userDetails?.user?.last_name);
    setValue("email", userDetails?.user?.email);
    setValue("phone_number", userDetails?.user?.phone_number);
    setValue("dob", userDetails?.dob);
    setValue("address", userDetails?.address);
    setValue("religion", userDetails?.religion);
    setValue("nationality", userDetails?.nationality);
    setValue("alt_phone", userDetails?.alt_phone);
    setValue("facebook_link", userDetails?.facebook_link);
    setValue("twitter_link", userDetails?.twitter_link);
    setValue("linkedIn_link", userDetails?.linkedIn_link);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, partyOption, divisionOption, upazilaOption]);
  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateUserProfile({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        dob: data.dob,
        address: data.address,
        religion: data.religion,
        nationality: data.nationality,
        political_party: data.political_party?.label,
        alt_phone: data.alt_phone,
        facebook_link: data.facebook_link,
        twitter_link: data.twitter_link,
        linkedIn_link: data.linkedIn_link,
        division: data?.division?.value,
        district: data?.district?.value,
        upazila: data?.upazila?.value,
        seat: data?.seat?.value,
      })
    );
  });

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (partyList?.length > 0) {
      partyList.map((item: PartyType) =>
        option.push({ value: item.id, label: item.name })
      );
      setPartyOption(option);
    }
  }, [partyList, setValue]);

  useEffect(() => {
    dispatch(getPartyList());
    dispatch(getDivisionList());
    dispatch(getDistrictList());
    dispatch(getUpazilaList());
    dispatch(getSeatList());
  }, [dispatch]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (division?.length > 0) {
      division?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setDivisionOption(option);
  }, [division]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (district?.length > 0) {
      district?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setDistrictOption(option);
  }, [district]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (upazila?.length > 0) {
      upazila?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setUpazilaOption(option);
  }, [upazila]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (seat?.length > 0) {
      seat?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setSeatOption(option);
  }, [seat]);

  return (
    <ModalContainer open={open} closeModal={closeModal}>
      <section className="pr_overlay fixed left-0 top-0 h-full w-full overflow-x-hidden overflow-y-auto bg-[rgba(0,0,0,0.5)] z-20">
        <div className="modal_dialog relative w-auto pointer-events-none transform-none xl:max-w-[1000px] lg:max-w-[950px] md:max-w-[700px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[340px] sm:min-h-[calc(100%_-_3.5rem)] min-h-[calc(100%_-_1rem)] flex items-center my-8 mx-auto">
          <div className="bg-white relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg p-6">
            <span
              className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-black bg-opacity-80 absolute -top-1 -right-0.5 z-50 cursor-pointer"
              id="em_close"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <div className="e_modal_body">
              <div className=" mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
                <div className="mb-9">
                  <h4 className="flex items-center justify-center text-gray-900 mb-1">
                    Welcome to
                    <span className="bg-gradient-to-r from-green-500 to-red-400 bg-clip-text fill-transparent ml-1">
                      NeTa
                    </span>
                  </h4>
                </div>
                <form onSubmit={onSubmit} className="bg-white">
                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("first_name", { required: true })}
                        id="firstName"
                        type="text"
                        name="first_name"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="firstName"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        First Name{" "}
                        <span className="text-red-500">&nbsp; *</span>
                      </label>
                      {errors?.first_name && (
                        <p className="text-red-500 text-start">
                          First name is required.
                        </p>
                      )}
                    </div>

                    <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("last_name", { required: true })}
                        id="lastName"
                        type="text"
                        name="last_name"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="lastName"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Last Name <span className="text-red-500">&nbsp; *</span>
                      </label>
                      {errors?.last_name && (
                        <p className="text-red-500 text-start">
                          Last name is required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("email", { required: true })}
                        id="email"
                        type="email"
                        name="email"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="email"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Email <span className="text-red-500">&nbsp; *</span>
                      </label>
                      {errors?.email && (
                        <p className="text-red-500 text-start">
                          Email name is required.
                        </p>
                      )}
                    </div>
                    <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("phone_number", { required: true })}
                        id="phone"
                        type="text"
                        name="phone_number"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="phone"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Phone Number{" "}
                        <span className="text-red-500">&nbsp; *</span>
                      </label>
                      {errors?.phone_number && (
                        <p className="text-red-500 text-start">
                          Phone Number is required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("dob")}
                        id="dob"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="dob"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Date of Birth
                      </label>
                    </div>
                    <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("address")}
                        id="address"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="address"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Permanent Address
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <Controller
                        name="division"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={divisionOption}
                            styles={customStyles}
                            className="mt-1"
                            isSearchable={true}
                            isClearable
                            // isDisabled={res_id || vendor ? true : false}
                          />
                        )}
                        control={control}
                        rules={{ required: true }}
                      />
                      <label
                        htmlFor="division"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Division
                      </label>
                    </div>
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <Controller
                        name="district"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={districtOption}
                            styles={customStyles}
                            className="mt-1"
                            isSearchable={true}
                            isClearable
                            // isDisabled={res_id || vendor ? true : false}
                          />
                        )}
                        control={control}
                        rules={{ required: true }}
                      />
                      <label
                        htmlFor="district"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        District
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <Controller
                        name="upazila"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={upazilaOption}
                            styles={customStyles}
                            className="mt-1"
                            isSearchable={true}
                            isClearable
                            // isDisabled={res_id || vendor ? true : false}
                          />
                        )}
                        control={control}
                        rules={{ required: true }}
                      />
                      <label
                        htmlFor="upazila"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Upazila
                      </label>
                    </div>
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <Controller
                        name="seat"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={seatOption}
                            styles={customStyles}
                            className="mt-1"
                            isSearchable={true}
                            isClearable
                            // isDisabled={res_id || vendor ? true : false}
                          />
                        )}
                        control={control}
                        rules={{ required: true }}
                      />
                      <label
                        htmlFor="seat"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Seat (Your neta selected based on seat)
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("religion")}
                        id="religion"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="religion"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Religion
                      </label>
                    </div>
                    <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("nationality")}
                        id="nationality"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="nationality"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Nationality
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <Controller
                        name="political_party"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={partyOption}
                            styles={customStyles}
                            className="mt-1"
                            isSearchable={true}
                            isClearable
                            // isDisabled={res_id || vendor ? true : false}
                          />
                        )}
                        control={control}
                        rules={{ required: true }}
                      />
                      <label
                        htmlFor="political_party"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Political Party
                      </label>
                    </div>
                    <div className="mb-4 relative w-full lg:w-1/2 bg-white group rounded-md">
                      <input
                        {...register("alt_phone")}
                        id="alt_phone"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="alt_phone"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Alternative Phone
                      </label>
                    </div>
                  </div>

                  <div className="flex flex-wrap flex-row ">
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/3 bg-white group rounded-md">
                      <input
                        {...register("facebook_link")}
                        id="facebook_link"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder="example:www.facebook.com"
                      />
                      <label
                        htmlFor="facebook_link"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Facebook
                      </label>
                    </div>
                    <div className="mb-4 pr-0 lg:pr-3 relative w-full lg:w-1/3 bg-white group rounded-md">
                      <input
                        {...register("twitter_link")}
                        id="twitter_link"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="twitter_link"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        Twitter
                      </label>
                    </div>
                    <div className="mb-4 relative w-full lg:w-1/3 bg-white group rounded-md">
                      <input
                        {...register("linkedIn_link")}
                        id="linkedIn_link"
                        type="text"
                        className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="linkedIn_link"
                        className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                      >
                        LinkedIn
                      </label>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ModalContainer>
  );
};

export default ProfileEditForm;
