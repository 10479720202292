import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { netaUsers } from "../../../store/API/MemberApis";
import Table from "../../../components/common/TableComponent";
import Pagination from "../../../components/common/Pagination";
import { getDistrictList, getDivisionList } from "../../../store/apis";
import { DistrictType } from "../../../models";
import { getPartyList } from "../../../store/API/CommonApis";

const NetaUserList = () => {
  const { netaList } = useAppSelector((state) => state.member);
  const { division, district, partyList } = useAppSelector(
    (state) => state.common
  );

  const [searchValue, setSearchValue] = useState("");
  const [districtOprion, setDistrictOprion] = useState<DistrictType[]>([]);
  const [divisionValue, setDivisionValue] = useState("");
  const [districtValue, setDistricValue] = useState("");
  const [partyListValue, setPartyListValue] = useState("");

  const [remountComponent, setRemountComponent] = useState(0);
  const [status, setStatus] = useState("approved");

  const dispatch = useAppDispatch();
  const limit = 10;
  useEffect(() => {
    dispatch(
      netaUsers({
        page: 1,
        page_size: limit,
      })
    );
    dispatch(getDivisionList());
    dispatch(getDistrictList());
    dispatch(getPartyList());
  }, [dispatch]);

  useEffect(() => {
    setDistrictOprion(district)
  }, [district])

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Phone", accessor: "phone" },
    { header: "Email", accessor: "email" },
    { header: "Type", accessor: "member_type" },
    // { header: "Neta", accessor: (item: any) => getAllMemberNames(item.member) },
  ];
  const handlePageClick = (data: any) => {
    dispatch(
      netaUsers({
        page: data.selected + 1,
        page_size: limit,
        search: searchValue,
      })
    );
    // setSelectedPage(data.selected + 1);
  };

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      dispatch(
        netaUsers({
          page: 1,
          page_size: limit,
          search: searchValue,
          status: status,
        })
      );
    }
    setRemountComponent(Math.random());
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
    dispatch(
      netaUsers({
        page: 1,
        page_size: limit,
        status: e.target.value,
      })
    );
  };
  const handleDivisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const filteredDistricts = district?.filter(
      (item: DistrictType) => item.division.id === parseInt(e.target.value)
    );
    setDistrictOprion(filteredDistricts);
    setDivisionValue(e.target.value);
    dispatch(
      netaUsers({
        page: 1,
        page_size: limit,
        division: e.target.value,
        political_party: partyListValue,
      })
    );
  };
  const handleDistrictChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDistricValue(e.target.value);
    dispatch(
      netaUsers({
        page: 1,
        page_size: limit,
        district: e.target.value,
        political_party: partyListValue,
      })
    );
  };

  const handlePartyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPartyListValue(e.target.value);
    dispatch(
      netaUsers({
        page: 1,
        page_size: limit,
        district: districtValue,
        division: divisionValue,
        political_party: e.target.value,
      })
    );
  };
  const handleNetaEdit = (item: any) => {
    const url = `/admin/neta/edit/${item.id}`;
    window.open(url, "_blank"); // Opens the URL in a new tab
  }

  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <div className="m-3">
        <p className="text-center">Neta List</p>
        <div className="flex items-center">
          {/* search bar */}
          <div className="relative md:w-1/3 my-2">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 ">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleSearch}
              id="simple-search"
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  "
              placeholder="Search here.."
            />
          </div>
          {/*  status select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={status}
                  onChange={handleStatusChange}
                  className="form-select 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 h-11"
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="REGULAR">Regular</option>
                  <option value="SHADOW">Shadow</option>
                  <option value="PROSPECTIVE">Prospective</option>
                </select>
              </div>
            </div>
          </div>
          {/*  division select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={divisionValue}
                  onChange={handleDivisionChange}
                  className="form-shandleStatusChangeelect 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 h-11"
                  aria-label="Default select example"
                >
                  <option value="">Select Division</option>
                  {division?.map((item: any) => (
                    <option value={item.id}>{item?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/*  Political select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={districtValue}
                  onChange={handleDistrictChange}
                  className="form-select 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 h-11"
                  aria-label="Default select example"
                >
                  <option value="">Select District</option>
                  {districtOprion?.map((item: any) => (
                    <option value={item.id}>{item?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/*  district select box*/}
          <div className="mx-2">
            <div className="flex justify-center">
              <div className="">
                <select
                  value={partyListValue}
                  onChange={handlePartyChange}
                  className="form-select 
      text-gray-700
      bg-white 
      border border-solid border-gray-300
      rounded
      focus:ring-gray-900 focus:border-gray-900 h-11"
                  aria-label="Default select example"
                >
                  <option value="">Select Political Party</option>
                  {partyList?.map((item: any) => (
                    <option value={item.id}>{item?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={netaList?.results || []}
          onEdit={handleNetaEdit}
          //   extraAction={handleApproveAmbassador}
        />
        <div className="mt-8 flex justify-end" key={remountComponent}>
          <Pagination
            pageCount={netaList?.count && Math.ceil(netaList?.count / limit)}
            handlePageClick={handlePageClick}
            pageRange={2}
          />
        </div>
      </div>
    </div>
  );
};

export default NetaUserList;
