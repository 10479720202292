import { createSlice } from "@reduxjs/toolkit";
import { PetitionResult, petitionType } from "../models";
import { getPetitionDetailsAction, myPetitionListAction, publicPetitionListAction } from "./API/PetitionApis";

const initialState: {
  loading: boolean;
  ownerPetitionList: PetitionResult | null;
  publicPetitionList: PetitionResult | null;
  petitionDetails: petitionType;
} = {
  loading: false,
  ownerPetitionList: null,
  publicPetitionList: null,
  petitionDetails: {}
};

const petitionSlice = createSlice({
  name: "petition",
  initialState: initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(myPetitionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.ownerPetitionList = action.payload;
    });
    builder.addCase(getPetitionDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.petitionDetails = action.payload;
    });
    builder.addCase(publicPetitionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.publicPetitionList = action.payload;
    });
  },
  reducers: {},
});

export const petitionActions = petitionSlice.actions;
export default petitionSlice.reducer;
