import { useParams } from "react-router-dom";
import PetitionBanner from "../../components/Petition/PetitionBanner";
import PetitionDetailsContent from "../../components/PetitionDetails/PetitionDetailsContent";
import SignaturesAllSignatures from "../../components/PetitionDetails/SignaturesAllSignatures";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { getPetitionDetailsAction } from "../../store/API/PetitionApis";

const PetitionDetails = () => {
  const { petitionDetails } = useAppSelector((state) => state.petition);

  const dispatch = useAppDispatch();
  let { id } = useParams();
  useEffect(() => {
    if (id) dispatch(getPetitionDetailsAction(parseInt(id, 10)));
  }, [id, dispatch]);
  return (
    <div className="text-left">
      <PetitionBanner />
      <section className="petition_area bg-[#fafbff] lg:py-10 py-8">
        <div className="md:container container-fluid mx-auto">
          <div className="lg:bg-white lg:p-4 rounded-md lg:shadow-theme_shadow">
            <div className="bg-blue-500 p-3 rounded shadow-theme_shadow">
              <p className="text-white sm:text-base text-sm font-normal text-justify">
                যেকোনো জনগুরুত্বপুর্ণ বিষয়ের পিটিশনে আমার নেতা ডটকম ১ হাজার সাইন
                হওয়ার পর একজন সংসদ সদস্যের মাধ্যমে পিটিশনের বিষয়টি জাতীয় সংসদে
                উপস্থাপনের চেষ্টা করবে। তবে এক হাজার বা ততোধিক সাইনই যেকোনো
                বিষয়ে আলোচনার জন্যে সর্বশেষ সংখ্যা নয়; এটা কর্তৃপক্ষের চাহিদা ও
                সামগ্রিক দিক বিবেচনা করে বাড়তেও পারে। এখানে উল্লেখ থাকে যে,
                প্রয়োজনীয় ডিজিটাল সাইনই যেকোনো বিষয়ে আলোচনার জন্যে সর্বশেষ শর্ত
                নয়, উপযুক্ত কর্তৃপক্ষ এ বিষয়ে সিদ্ধান্ত দেওয়ার অধিকার সংরক্ষণ
                করেন।
              </p>
            </div>
            <div className="card p-3">
              <div className="card_body">
                <a href="petition-details.html">
                  <h4 className="text-green-500 xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold pb-6 hover:text-red-500 transition-all">
                    {petitionDetails?.title}
                  </h4>
                </a>
                <PetitionDetailsContent />
                <SignaturesAllSignatures />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PetitionDetails;
