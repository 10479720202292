import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { getPublicUserProfile } from "../../store/API/UserApis";
// import { userAmbassadorQuestionListAction, userQuestionListAction } from "../../store/API/QuestionAnswerApis";
import { useSearchParams } from "react-router-dom";
import PublicProfileDetails from "../../components/PublicProfile/ProfileDetails";

const PublicProfile = () => {
  const [toggleTab, setToggleTab] = useState(1);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const user = searchParams.get('user') as string;
  // const limit = 4;
  useEffect(() => {
    dispatch(getPublicUserProfile(user));
    // dispatch(userQuestionListAction({ page: 1, page_size: limit }));
    // dispatch(userAmbassadorQuestionListAction({ page: 1, page_size: limit }));
  }, [dispatch, user]);
  return (
    <section className="amb_profile bg-[#fafbff] lg:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="max-w-[850px] w-full mx-auto">
          <ul className="flex gap-3 whitespace-nowrap overflow-x-auto">
            <li>
              <button
                type="button"
                onClick={() => setToggleTab(1)}
                className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal ${
                  toggleTab === 1
                    ? "bg-red-500 hover:bg-red-500"
                    : "hover:bg-red-500"
                }`}
              >
                Profile
              </button>
            </li>
            {/* <li>
              <button
                type="button"
                onClick={() => setToggleTab(2)}
                className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal ${
                  toggleTab === 2
                    ? "bg-red-500 hover:bg-red-500"
                    : "hover:bg-red-500"
                }`}
              >
                Questions
              </button>
            </li> */}
          </ul>
          <div className="p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300 mt-7">
            {toggleTab === 1 && <PublicProfileDetails />}

            {/* {toggleTab === 2 && <ProfileQuestionList />} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PublicProfile;
