
import { useAppSelector } from '../../../store/hooks';


type PropsType = {
  handleMemberTypeClick: (data: any) => void;
  activeButton: number;
  setActiveButton: React.Dispatch<React.SetStateAction<number>>;
};

const ListSidebar = (props: PropsType) => {
  const { memberList } = useAppSelector((state) => state.member);
  return (
    <div className="md:w-[200px] w-full">
      <ul className="flex md:flex-col flex-row gap-3 whitespace-nowrap overflow-x-auto">
        <li>
          <button
            type="button"
            //   :className="openTab === 1 ? activeClasses : inactiveClasses"
            className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal  ${
              props.activeButton === 1
                ? "bg-red-500 hover:bg-red-500"
                : "hover:bg-red-500"
            }`}
            onClick={() => {
              props.handleMemberTypeClick("");
              props.setActiveButton(1);
            }}
          >
            Total MP({memberList?.results?.total})
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              props.handleMemberTypeClick("REGULAR");
              props.setActiveButton(2);
            }}
            //   :className="openTab === 2 ? activeClasses : inactiveClasses"
            className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal  ${
              props.activeButton === 2
                ? "bg-red-500 hover:bg-red-500"
                : "hover:bg-red-500"
            }`}
          >
            Regular MP({memberList?.results?.regular_member_count})
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.handleMemberTypeClick("SHADOW");
              props.setActiveButton(3);
            }}
            type="button"
            //   :className="openTab === 3 ? activeClasses : inactiveClasses"
            className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal  ${
              props.activeButton === 3
                ? "bg-red-500 hover:bg-red-500"
                : "hover:bg-red-500"
            }`}
          >
            Shadow MP({memberList?.results?.shadow_member_count})
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.handleMemberTypeClick("PROSPECTIVE");
              props.setActiveButton(4);
            }}
            type="button"
            //   :className="openTab === 4 ? activeClasses : inactiveClasses"
            className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal  ${
              props.activeButton === 4
                ? "bg-red-500 hover:bg-red-500"
                : "hover:bg-red-500"
            }`}
          >
            Prospective MP({memberList?.results?.prospective_member_count})
          </button>
        </li>
      </ul>
    </div>
  );
}

export default ListSidebar