import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
import { petitionType } from "../../models";
import parse from "html-react-parser";

const PetitionList = () => {
  const { publicPetitionList } = useAppSelector((state) => state.petition);
  return (
    <section className="petition_area bg-[#fafbff] lg:py-10 py-8">
      <div className="container mx-auto flex flex-col gap-20">
        <div className="bg-white p-4 rounded-md shadow-theme_shadow max-w-[800px] mx-auto">
          <div className="bg-blue-500 p-3 rounded shadow-theme_shadow">
            <p className="text-white sm:text-base text-sm font-normal text-justify">
              যেকোনো জনগুরুত্বপুর্ণ বিষয়ের পিটিশনে আমার নেতা ডটকম ১ হাজার সাইন
              হওয়ার পর একজন সংসদ সদস্যের মাধ্যমে পিটিশনের বিষয়টি জাতীয় সংসদে
              উপস্থাপনের চেষ্টা করবে। তবে এক হাজার বা ততোধিক সাইনই যেকোনো বিষয়ে
              আলোচনার জন্যে সর্বশেষ সংখ্যা নয়; এটা কর্তৃপক্ষের চাহিদা ও সামগ্রিক
              দিক বিবেচনা করে বাড়তেও পারে। এখানে উল্লেখ থাকে যে, প্রয়োজনীয়
              ডিজিটাল সাইনই যেকোনো বিষয়ে আলোচনার জন্যে সর্বশেষ শর্ত নয়, উপযুক্ত
              কর্তৃপক্ষ এ বিষয়ে সিদ্ধান্ত দেওয়ার অধিকার সংরক্ষণ করেন।
            </p>
          </div>
          <div className="card p-3">
            <div className="card_head py-5">
              <h3 className="inline-block text-green-500 xl:text-2xl md:text-xl text-lg font-medium relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
                Petition
              </h3>
            </div>
            {publicPetitionList?.results &&
            publicPetitionList?.results?.length > 0 ? (
              publicPetitionList?.results?.map((item: petitionType) => (
                <div
                  className="card_body bg-white py-8 px-4 rounded-md shadow-theme_shadow"
                  key={item?.id}
                >
                  <Link to={`/petition/details/${item?.id}`}>
                    <h4 className="text-black md:text-xl text-lg font-medium pb-2 hover:text-green-500 transition-all">
                      {item?.title}
                    </h4>
                  </Link>
                  <div className="flex flex-wrap items-center gap-2">
                    <p className="text-green-500 text-base font-normal">
                      Petition Created By:
                      <span className="font-medium">{item?.created_by}</span>,
                    </p>
                    <span className="text-[#4a4a4a] text-opacity-70">
                      {item?.published_datetime
                        ? moment(item.published_datetime).format(
                            "MMM D, YYYY h:mm A"
                          )
                        : "N/A"}
                    </span>
                  </div>
                  <p className="text-green-500 text-sm font-normal">
                    Total Signaure: {item?.target}
                  </p>
                  <div className="relative flex items-start justify-start">
                    <div className="text-gray-700 py-2 max-h-28 text-clip overflow-hidden">
                      {/* {i?.content} */}
                      {item?.description && parse(item?.description)}
                    </div>
                    <Link
                      to={`/petition/details/${item?.id}`}
                      className="bg-gradient-to-l from-[#ffffff94] to-[#ffffff85] py-1 px-[6px] backdrop-filter rounded w-full absolute -bottom-1  text-center hover:scale-110"
                    >
                      <div className="text-gray-900 text-center font-normal hover:text-green-500">
                        View More
                      </div>
                    </Link>
                  </div>
                  {/* <p className="text-black text-opacity-70 text-base font-normal">
                  ২০১৭ সালের মাধ্যমিক পরীক্ষার প্রশ্নপত্র ফাঁস সংক্রান্ত
                  অনুসন্ধান
                </p> */}
                </div>
              ))
            ) : (
              <p className="text-black text-base font-bold">No Petition Found</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PetitionList;
