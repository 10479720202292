import { useAppSelector } from "../../store/hooks";
import { OnlyUpazilaType } from "../../models";

const PublicProfileDetails = () => {
  const { publicUserDetails } = useAppSelector((state) => state.auth);

  return (
    <div x-show="openTab === 1">
      <div className=" relative flex gap-4">
        <div className="flex flex-col items-center">
          <label htmlFor="profileImageInput">
            <div className="w-32 h-32 rounded-full mb-4 shrink-0 cursor-pointer">
              <img
                className="w-32 h-32 rounded-full absolute"
                width={300}
                height={300}
                src={publicUserDetails?.profile_pic}
                alt="Profile"
              />

              <div className="w-32 h-32 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                <img
                  className="hidden group-hover:block w-12"
                  width={300}
                  height={300}
                  src="https://www.svgrepo.com/show/33565/upload.svg"
                  alt=""
                />
              </div>
            </div>
          </label>
        </div>
        <div className="amb_content">
          <h4 className="text-black lg:text-3xl md:text-2xl text-xl font-medium">
            {publicUserDetails?.user?.first_name}{" "}
            {publicUserDetails?.user?.last_name}
          </h4>
          <span className="text-[#838383] text-base font-normal">
            {publicUserDetails?.upazila?.map(
              (item: OnlyUpazilaType) => item?.name
            )}
            , {publicUserDetails?.seat?.name}
          </span>
        </div>
      </div>
      <ul className="flex flex-col gap-3 text-[#4a4a4a] text-opacity-70 md:text-base text-sm font-normal mb-8">
        <li className="flex items-center gap-2">
          <span className="text-black">Name :</span>
          <span>
            {publicUserDetails?.user?.first_name}{" "}
            {publicUserDetails?.user?.last_name}
          </span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Email</span>
          <span>{publicUserDetails?.user?.email}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Phone</span>
          <span>{publicUserDetails?.user?.phone_number}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Date of Birth</span>
          <span>{publicUserDetails?.dob}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Permanent Address :</span>
          <span>{publicUserDetails?.address}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Religion</span>
          <span>{publicUserDetails?.religion}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Nationality</span>
          <span>{publicUserDetails?.nationality}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Political Party</span>
          <span>{publicUserDetails?.political_party}</span>
        </li>
        <li className="flex items-center gap-2">
          <span className="text-black">Alternative Phone</span>
          <span>{publicUserDetails?.alt_phone}</span>
        </li>
      </ul>
      <div className="border-b border-gray-300 mb-6">
        <h6 className="text-gray-900 mb-1">Social Media Links</h6>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-7">
        {publicUserDetails?.facebook_link && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                publicUserDetails?.facebook_link
                  ? `https://${publicUserDetails?.facebook_link}`
                  : "#"
              }
              className="group px-[22px] py-2 rounded-full font-medium text-base flex items-center justify-center hover:bg-green-25 bg-white border border-green-500 text-gray-700 hover:text-green-500 transition-colors duration-200"
            >
              <svg
                className="w-[14px] h-[14px] fill-gray-700 group-hover:fill-green-500 mr-[10px]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"></path>
              </svg>
              Connect to Facebook
            </a>
          </div>
        )}
        {publicUserDetails?.twitter_link && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                publicUserDetails?.twitter_link
                  ? `https://${publicUserDetails?.twitter_link}`
                  : "#"
              }
              className="group px-[22px] py-2 rounded-full font-medium text-base flex items-center justify-center hover:bg-green-25 bg-white border border-green-500 text-gray-700 hover:text-green-500 transition-colors duration-200"
            >
              <svg
                className="w-[14px] h-[14px] fill-gray-700 group-hover:fill-green-500 mr-[10px]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.55016 21.75C16.6045 21.75 21.5583 14.2468 21.5583 7.74192C21.5583 7.53098 21.5536 7.31536 21.5442 7.10442C22.5079 6.40752 23.3395 5.54432 24 4.55536C23.1025 4.95466 22.1496 5.21544 21.1739 5.3288C22.2013 4.71297 22.9705 3.74553 23.3391 2.60583C22.3726 3.17862 21.3156 3.58267 20.2134 3.80067C19.4708 3.01162 18.489 2.48918 17.4197 2.31411C16.3504 2.13905 15.2532 2.32111 14.2977 2.83216C13.3423 3.3432 12.5818 4.15477 12.1338 5.14137C11.6859 6.12798 11.5754 7.23468 11.8195 8.29036C9.86249 8.19215 7.94794 7.68377 6.19998 6.79816C4.45203 5.91255 2.90969 4.6695 1.67297 3.14958C1.0444 4.2333 0.852057 5.51571 1.13503 6.73615C1.418 7.9566 2.15506 9.02351 3.19641 9.72005C2.41463 9.69523 1.64998 9.48474 0.965625 9.10598V9.16692C0.964925 10.3042 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1932 3.43198 14.2221 2.69484 14.0794C3.00945 15.0575 3.62157 15.913 4.44577 16.5264C5.26997 17.1398 6.26512 17.4807 7.29234 17.5013C5.54842 18.8712 3.39417 19.6142 1.17656 19.6107C0.783287 19.6101 0.390399 19.586 0 19.5385C2.25286 20.9838 4.87353 21.7514 7.55016 21.75Z"></path>
              </svg>
              Connect to Twitter
            </a>
          </div>
        )}
        {publicUserDetails?.linkedIn_link && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                publicUserDetails?.linkedIn_link
                  ? `https://${publicUserDetails?.linkedIn_link}`
                  : "#"
              }
              className="group px-[22px] py-2 rounded-full font-medium text-base flex items-center justify-center hover:bg-green-25 bg-white border border-green-500 text-gray-700 hover:text-green-500 transition-colors duration-200"
            >
              <svg
                className="w-[14px] h-[14px] fill-gray-700 group-hover:fill-green-500 mr-[10px]"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"></path>
              </svg>
              Connect to Linkedin
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicProfileDetails;
