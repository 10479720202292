import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MyPetitionList from "./MyPetitionList";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { myPetitionListAction } from "../../store/API/PetitionApis";
import Pagination from "../common/Pagination";

const MyPetition = () => {
  const { ownerPetitionList } = useAppSelector((state) => state.petition);
  const dispatch = useAppDispatch();
  // const [remountComponent, setRemountComponent] = useState(0);
  // const navigate = useNavigate();
  const limit = 10;
  useEffect(() => {
    dispatch(myPetitionListAction({ page: 1, page_size: limit }));
  }, [dispatch]);

  const handlePageClick = (data: any) => {
    dispatch(
      myPetitionListAction({
        page: data.selected + 1,
        page_size: limit,
      })
    );
  };
  return (
    <div x-show="openTab === 2">
      <div className="flex justify-start">
        <Link
          to={`/petition/create`}
          className="bg-green-500 p-2 rounded-md text-white md:text-base text-sm font-normal inline-flex items-center space-x-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          <span>Create Petition</span>
        </Link>
      </div>
      <h4 className="text-green-500 lg:text-3xl md:text-2xl text-xl font-medium">
        My Petition
      </h4>
      <MyPetitionList />
      <div className="mt-8 flex justify-end">
        <Pagination
          pageCount={
            ownerPetitionList?.count &&
            Math.ceil(ownerPetitionList?.count / limit)
          }
          handlePageClick={handlePageClick}
          pageRange={5}
        />
      </div>
    </div>
  );
};

export default MyPetition;
