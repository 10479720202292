import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getDistrictList, getSeatList, getUpazilaList } from "../../store/apis";
import SelectComponent from "../CommonComponent/SelectComponent";
import { SeatType, UpazilaType } from "../../models";
import { followNeta } from "../../store/API/UserApis";

const NetaSelectForm = () => {
  const { district, upazila, seat } = useAppSelector((state) => state.common);
  const { followMemberMgs } = useAppSelector((state) => state.auth);

  const [districtData, setDistrictData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [upazilaData, setUpazilaData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [seatData, setSeatData] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [districtOption, setDistrictOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [upazilaOption, setUpazilaOption] = useState<
    { value: number; label: string }[]
  >([]);
  const [seatOption, setSeatOption] = useState<
    { value: number; label: string }[]
    >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDistrictList());
    dispatch(getUpazilaList());
    dispatch(getSeatList());
  }, [dispatch]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (district?.length > 0) {
      district?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setDistrictOption(option);
  }, [district]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (upazila?.length > 0) {
      upazila?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setUpazilaOption(option);
  }, [upazila]);

  useEffect(() => {
    let option: { value: number; label: string }[] = [];
    if (seat?.length > 0) {
      seat?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
    }
    setSeatOption(option);
  }, [seat]);


  const handleDistrictSelectChange = (selectedOption: any) => {
    setDistrictData(selectedOption);
    setUpazilaData(null);
    if (selectedOption) {
      const filteredUpazila = upazila?.filter(
        (item: UpazilaType) => item.district.id === selectedOption.value
      );
      let option: { value: number; label: string }[] = [];
      if (filteredUpazila) {
        filteredUpazila.map((item: { id: number; name: string }) =>
          option.push({ value: item.id, label: item.name })
        );
      }
      setUpazilaOption(option);
    } else {
      // If no division is selected, show all districts
      let option: { value: number; label: string }[] = [];
      district?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
      setDistrictOption(option);
    }
  };

  const handleUpazilaSelectChange = (selectedOption: any) => {
    setUpazilaData(selectedOption);

    if (selectedOption) {
      const filteredSeat = seat?.filter((item: SeatType) =>
        item.upazila.includes(selectedOption.value)
      );
      let option: { value: number; label: string }[] = [];
      if (filteredSeat) {
        filteredSeat.map((item: { id: number; name: string }) =>
          option.push({ value: item.id, label: item.name })
        );
      }

      setSeatOption(option);
    } else {
      let option: { value: number; label: string }[] = [];
      upazila?.map((item: { id: number; name: string }) =>
        option.push({ value: item.id, label: item.name })
      );
      setUpazilaOption(option);
      // setUpazilaData(null);
    }
  };

  const handleSeatSelectChange = (selectedOption: any) => {
    setSeatData(selectedOption);
  };

  const handleSubmit = async () => {
    try {
      if (seatData?.value && districtData?.value && upazilaData?.value) {
        await dispatch(
          followNeta({
            seat: seatData?.value,
            district: districtData?.value,
            upazila: upazilaData?.value,
            router: navigate,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="login_area bg-[#fafbff] lg:py-20 sm:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="max-w-[585px] w-full mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
          <div className="mb-9">
            <h4 className="flex items-center justify-center text-gray-900 mb-1">
              Welcome to
              <span className="bg-gradient-to-r from-green-500 to-red-400 bg-clip-text fill-transparent ml-1">
                NeTa
              </span>
            </h4>
            <p className="font-normal text-gray-700 text-center">
              Please Give Your Address Information
            </p>
          </div>
          <form>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <SelectComponent
                selectOptions={districtOption}
                selectData={districtData ? districtData : undefined}
                setSingleSelectData={setDistrictData}
                handleSelectChange={handleDistrictSelectChange}
                placeholder="Choose Your District"
                searchable={true}
              />
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <SelectComponent
                selectOptions={upazilaOption}
                selectData={upazilaData}
                setSingleSelectData={setUpazilaData}
                handleSelectChange={handleUpazilaSelectChange}
                placeholder="Choose Your Upazila"
                searchable={true}
              />
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <SelectComponent
                selectOptions={seatOption}
                selectData={seatData}
                setSingleSelectData={setSeatData}
                handleSelectChange={handleSeatSelectChange}
                placeholder="Choose Your Seat"
                searchable={true}
              />
            </div>

            <button
              type="button"
              onClick={handleSubmit}
              className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
            >
              Submit
            </button>
          </form>
          {followMemberMgs && (
            <div className="mt-4 p-3 bg-red-100 text-red-700 rounded">
              <p>{followMemberMgs}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default NetaSelectForm;
