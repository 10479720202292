import { createSlice } from "@reduxjs/toolkit";
import { MemberDetailsType, MemberListType, MemberType, NetaResults } from "../models";
import { ambassadorSuggestion, getMemberDetails, getMemberList, getMostAnswerMember, getMostQuestionMember, netaUserDetails, netaUsers } from "./API/MemberApis";

const initialState: {
  loading: boolean;
  mostQuestionMember: MemberType[];
  memberList: MemberListType | null;
  mostAnswerMember: MemberType[];
  memberDetails: MemberDetailsType | null;
  membersuggestions: MemberType[];
  netaList: NetaResults | null;
  netaDetails: MemberType | null;
} = {
  loading: false,
  mostQuestionMember: [],
  mostAnswerMember: [],
  memberList: null,
  memberDetails: null,
  membersuggestions: [],
  netaList: null,
  netaDetails: null,
};

const memberSlice = createSlice({
  name: "member",
  initialState: initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getMostQuestionMember.fulfilled, (state, action) => {
      state.loading = false;
      state.mostQuestionMember = action.payload;
    });
    builder.addCase(getMostAnswerMember.fulfilled, (state, action) => {
      state.loading = false;
      state.mostAnswerMember = action.payload;
    });
    builder.addCase(getMemberDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.memberDetails = action.payload;
    });
    builder.addCase(getMemberList.fulfilled, (state, action) => {
      state.loading = false;
      state.memberList = action.payload;
    });
    builder.addCase(getMemberList.pending, (state, action) => {
      state.loading = true;
      state.memberList = null;
    });
    builder.addCase(getMemberList.rejected, (state, action) => {
      state.loading = false;
      state.memberList = null;
    });
    builder.addCase(ambassadorSuggestion.fulfilled, (state, action) => {
      state.loading = false;
      state.membersuggestions = action.payload;
    });
    builder.addCase(netaUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.netaList = action.payload;
    });
    builder.addCase(netaUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.netaDetails = action.payload;
    });
  },
  reducers: {},
});

export const commonActions = memberSlice.actions;
export default memberSlice.reducer;
