import React from "react";
import ModalContainer from "../../common/ModalContainer";
import Table from "../../common/TableComponent";
import { useAppDispatch } from "../../../store/hooks";
import {
  ambassadorApprove,
  ambassadorRemove,
} from "../../../store/API/MemberApis";

type PropsTypes = {
  openAmbassadorModal: boolean;
  closeAmbassadorModel: () => void;
  data: any;
  search_key: string;
  user_type: string;
};

const AmbassadorApproveModal: React.FC<PropsTypes> = ({
  closeAmbassadorModel,
  openAmbassadorModal,
  data,
  search_key,
  user_type,
}) => {
  const dispatch = useAppDispatch();

  const handleRemoveAmbassador = (member: any) => {
    dispatch(
      ambassadorRemove({
        user_id: data?.id,
        member_id: member?.id,
        search_key,
        user_type,
      })
    );
    closeAmbassadorModel();
  };

  const handleApproveAmbassador = (member: any) => {
    dispatch(
      ambassadorApprove({
        user_id: data?.id,
        member_id: member?.id,
        search_key,
        user_type,
      })
    );
    closeAmbassadorModel();
  };

  const columns1 = [
    { header: "Name", accessor: "name" },
    { header: "Phone", accessor: "phone" },
  ];
  const columns2 = [
    { header: "Name", accessor: "name" },
    { header: "Phone", accessor: "phone" },
  ];
  return (
    <ModalContainer
      open={openAmbassadorModal}
      closeModal={closeAmbassadorModel}
    >
      <div className="pr_overlay fixed left-0 top-0 h-full w-full overflow-x-hidden overflow-y-auto bg-[rgba(0,0,0,0.5)] z-20">
        <div className="modal_dialog relative w-auto transform-none xl:max-w-[1000px] lg:max-w-[950px] md:max-w-[700px] sm:max-w-[500px] xs:max-w-[400px] xxs:max-w-[340px] sm:min-h-[calc(100%_-_3.5rem)] min-h-[calc(100%_-_1rem)] flex items-center my-8 mx-auto">
          <div className="bg-white relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg p-6">
            <span
              className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-black bg-opacity-80 absolute -top-1 -right-1 z-50 cursor-pointer"
              id="em_close"
              onClick={closeAmbassadorModel}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <div className="e_modal_body mb-3">
              <h1 className="font-bold mb-3 text-center">
                Ambassador Approved
              </h1>
              <Table
                columns={columns1}
                data={
                  data?.member?.map((item: any) => ({
                    ...item,
                    status: true,
                  })) || []
                }
                extraAction={handleRemoveAmbassador}
              />
            </div>
            <div className="e_modal_body mb-3">
              <h1 className="font-bold mb-3 text-center">Ambassador Request</h1>
              <Table
                columns={columns2}
                extraAction={handleApproveAmbassador}
                data={
                  Array.isArray(data?.request_ambassadors)
                    ? data.request_ambassadors
                    : data?.request_ambassadors
                    ? [{ ...data.request_ambassadors, status: false }]
                    : []
                }
              />
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AmbassadorApproveModal;
