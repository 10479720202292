import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { editNeta, netaUserDetails } from "../../../store/API/MemberApis";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDistrictList,
  getDivisionList,
  getSeatList,
  getUpazilaList,
} from "../../../store/apis";
import { getPartyList } from "../../../store/API/CommonApis";
import NetaCreateForm from "../../../components/Admin/Neta/NetaCreateForm";

const NetaEdit = () => {
  const { netaDetails } = useAppSelector((state) => state.member);
  let { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) dispatch(netaUserDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getDivisionList());
    dispatch(getDistrictList());
    dispatch(getUpazilaList());
    dispatch(getSeatList());
    dispatch(getPartyList());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleSubmit = async (
    formData: any,
    profilePic: File | null,
    coverPic: File | null,
    divisionData: any,
    districtData: any,
    upazilaData: any[],
    seatData: any,
    memberType: any,
    politicalParty: any
  ) => {
    
    const formDataToSubmit = new FormData();
    // Append all the form data (text inputs) to FormData
    Object.keys(formData).forEach((key) => {
      const value = formData[key as keyof typeof formData];
      if (value !== null && value !== undefined && value !== "") {
        formDataToSubmit.append(key, value);
      }
    });

    // Append profile_pic as a file (only if a file was selected)
    if (profilePic) {
      formDataToSubmit.append("profile_pic", profilePic);
    }
    if (coverPic) {
      formDataToSubmit.append("cover_pic", coverPic);
    }

    if (divisionData) {
      formDataToSubmit.append("division", divisionData.value.toString());
    }
    if (districtData) {
      formDataToSubmit.append("district", districtData.value.toString());
    }
    if (upazilaData && upazilaData.length > 0) {
      upazilaData.forEach((upazila) => {
        formDataToSubmit.append("upazila", upazila.value.toString());
      });
    }
    if (seatData) {
      formDataToSubmit.append("seat", seatData.value.toString());
    }

    if (memberType) {
      formDataToSubmit.append("member_type", memberType.value.toString());
    }

    if (politicalParty) {
      formDataToSubmit.append(
        "political_party",
        politicalParty.value.toString()
      );
    }

    formDataToSubmit.append("status", "true");

    try {
      await dispatch(editNeta({ formData: formDataToSubmit, navigate, id }));
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-gray-800 w-full">
        <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
          <h3 className="font-semibold text-black dark:text-white">
            Create Neta
          </h3>
        </div>
        <NetaCreateForm onSubmit={handleSubmit} details={netaDetails!} />
      </div>
    </div>
  );
};

export default NetaEdit;
