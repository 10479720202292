import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './store';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const helmetContext = {};
root.render(
  <>
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
