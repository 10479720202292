import { useEffect } from "react";
import AnswerArea from "../../components/Home/AnswerArea";
import Banner from "../../components/Home/Banner";
import CountdownArea from "../../components/Home/CountdownArea";
import LatestNews from "../../components/Home/LatestNewa";
import NewsArea from "../../components/Home/NewsArea";
import QuestionArea from "../../components/Home/QuestionArea";
import RecentArea from "../../components/Home/RecentArea";
import SearchNeta from "../../components/Home/SearchNeta";
import { useAppDispatch } from "../../store/hooks";
import { getGeneralNews, getTopNews } from "../../store/API/NewsApis";
import {
  getMostAnswerMember,
  getMostQuestionMember,
} from "../../store/API/MemberApis";
import { recentAnswer, topQuestion } from "../../store/API/QuestionAnswerApis";
import {
  getHomePageSummery,
  getLatestVideos,
  getPageAdd,
} from "../../store/API/CommonApis";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getTopNews());
    dispatch(getMostQuestionMember());
    dispatch(getMostAnswerMember());
    dispatch(topQuestion());
    dispatch(recentAnswer());
    dispatch(getLatestVideos({ category: "GENERAL" }));
    dispatch(getGeneralNews());
    dispatch(getHomePageSummery());
    dispatch(getPageAdd("home"));
  }, [dispatch]);
  const handleSearchSubmit = (data: any) => {
    if (data?.seat) {
      navigate(`/neta/list?seat=${data?.seat?.value}`);
    } else if (data?.upazila) {
      navigate(`/neta/list?upazila=${data?.upazila?.value}`);
    } else if (data?.district) {
      navigate(`/neta/list?district=${data?.district?.value}`);
    } else if (data?.division) {
      navigate(`/neta/list?division=${data?.division?.value}`);
    } else {
      navigate(`/neta/list`);
    }
  };
  return (
    <>
      <Banner />
      <SearchNeta handleSearchSubmit={handleSearchSubmit} title="Your Leader" />
      <NewsArea />
      <QuestionArea />
      <AnswerArea />
      <RecentArea />
      <CountdownArea />
      <LatestNews />
    </>
  );
};

export default Home;
